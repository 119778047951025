<template>
    <div
        v-if="text !== 'KERN' && text !== 'FK'"
        :class="{
            'product-label': true,
            'full-radius': !radiusRight,
            [`color-${text.toLowerCase().replace(' ', '-')}`]: true,
            [`${textColor}`]: true,
        }"
        @mouseenter="mouseEnterInfo($event)"
        @mouseleave="mouseLeaveInfo"
        @click="clickEvent"
    >
        <div class="text">
            {{ text }}
        </div>

        <Teleport to="body" :disabled="!renderTooltipInBody">
            <AtomTooltip class="tooltip-color" :element="tooltipElement" :offset-y="10" tooltip-type="default">
                {{ $t(`Product.${text.toLowerCase().replace(' ', '_')}_tooltip`) }}
            </AtomTooltip>
        </Teleport>
    </div>
</template>
<script lang="ts" setup>
const props = withDefaults(
    defineProps<{
        text: string
        icon?: string
        radiusRight?: boolean
        renderTooltipInBody?: boolean
    }>(),
    {
        icon: '',
        radiusRight: false,
        renderTooltipInBody: false,
    }
)

const tooltipElement = ref<HTMLElement | null>(null)

const textColor = computed(() => {
    if (props.text === 'SAISON' || props.text === 'FK') {
        return 'text-black'
    }

    return 'text-white'
})

const mouseEnterInfo = (event: MouseEvent) => {
    tooltipElement.value = event.target as HTMLElement
}

const mouseLeaveInfo = () => {
    tooltipElement.value = null
}

const clickEvent = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
}
</script>
<style scoped lang="scss">
.product-label {
    padding: sp(xxs) sp(xs);
    height: rem(24);
    width: fit-content;
    text-align: center;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;

    @include helper-color(white);
    @include helper-border-radius-r(rem(16));
    @include helper-font-weight(medium);

    &.full-radius {
        @include helper-border-radius(rem(16));
    }

    &.text-black {
        @include helper-color(text-title);
    }

    &.text-white {
        @include helper-color(white);
    }

    &.color-nos {
        @include helper-color-bg(label-nos);
    }

    &.color-saison {
        @include helper-color-bg(label-saison);
    }

    &.color-fk {
        @include helper-color-bg(label-fk);
    }

    &.color-kern {
        @include helper-color-bg(label-kern);
    }

    &.color-ps {
        @include helper-color-bg(label-ps);
    }

    &.color-ms {
        @include helper-color-bg(label-ms);
    }

    &.color-w {
        @include helper-color-bg(label-w);
    }

    &.color-netto {
        @include helper-color-bg(label-netto);
    }

    &.color-cat-o {
        @include helper-color-bg(label-cat-o);
    }

    &.color-cat-op {
        @include helper-color-bg(label-cat-op);
    }

    .tooltip-color {
        @include helper-color(text-title);
    }
}
</style>
