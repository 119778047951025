import useStore from '~/composables/service/useStore'

export const usePreferencesStore = defineStore('usePreferences', () => {
    const { getCachedData, updateCachedData } = useLocalStorageCache<boolean>(LocalStorageNameTypes.SHOW_EK_PRICES)

    const cachedData = ref<boolean>(getCachedData() ?? true)

    const showNoEKPrices = computed(() => {
        if (useStore().isVoStore()) {
            return true
        }

        return cachedData.value
    })

    const updateShowNOEKPrices = (value: boolean) => {
        cachedData.value = value
        updateCachedData(value)
        window.dispatchEvent(new StorageEvent('storage'))
    }

    return {
        showNoEKPrices,
        updateShowNOEKPrices,
    }
})
