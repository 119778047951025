import type { MatrixStoredOrderItem } from '~/composables/service/matrix/useMatrixOrderItems'
import type {
    CachedDeliveryDateData,
    CachedDeliveryDateObj,
    MatrixAvailableDeliveryDate,
    MatrixData,
    MatrixDeliveryDate,
} from '~/composables/types/api/searchDiscover/getMatrix'
import type { NosSelectionItem } from '~/composables/service/matrix/useMatrixNosItems'
import type { MatrixNosItem, MatrixOrderItem } from '~/composables/types/api/cartConditions/matrix'
import useStore from '~/composables/service/useStore'

const { getCachedData, updateCachedData } = useLocalStorageCache<CachedDeliveryDateData[]>(
    LocalStorageNameTypes.PRODUCT_SAVED_DELIVERY_DATE
)

type DateFormat = 'DD' | 'MM' | 'YYYY' | 'DD.MM' | 'MM.YYYY' | 'DD.MM.YYYY' | 'DD.MM.YY'
const dateTypeWeek = 2
const dateTypeMonth = 3
let warningDays = 3

const isInRange = (range: { fromDate: string; toDate: string }, date: string) =>
    date >= range.fromDate && date <= range.toDate

const findInvalidOrders = (
    orderItems: MatrixOrderItem[],
    deliveryDates: MatrixAvailableDeliveryDate[]
): MatrixOrderItem[] =>
    orderItems.filter((item) => {
        const deliveryDate = deliveryDates.find((dd) => dd.gtin === item.gtin)

        if (!deliveryDate) {
            return true
        }
        const isDeadlineMatching = item.orderDeadline === deliveryDate.deadlineDate

        const isDeliveryDateValid =
            new Date(item.deliveryDate) >= new Date(deliveryDate.fromDate) &&
            new Date(item.deliveryDate) <= new Date(deliveryDate.toDate)

        return !(isDeadlineMatching && isDeliveryDateValid)
    })

const checkDeliveryDateDeadline = (
    activeTabDeadlineDate: string | null
): 'default' | 'warning' | 'error' | 'success' | undefined => {
    const days = warningDays
    const now = useMatrixDateHelper().dateToString()
    const activeDeadlineDate = useMatrixDateHelper().dateToString(new Date(activeTabDeadlineDate ?? ''))

    const warningDate = new Date(activeDeadlineDate)
    warningDate.setDate(new Date(activeTabDeadlineDate ?? '').getDate() - days)

    if (now < useMatrixDateHelper().dateToString(warningDate)) {
        return 'default'
    } else if (now >= useMatrixDateHelper().dateToString(warningDate) && now <= activeDeadlineDate) {
        return 'warning'
    } else {
        return 'error'
    }
}

const filterAvailableDeliveryDates = (
    getAllAvailableDeliveryDates: (validOnly: boolean) => MatrixAvailableDeliveryDate[]
) =>
    getAllAvailableDeliveryDates(true)?.filter(
        (date, index, dates) =>
            index === 0 || date.fromDate !== dates[index - 1].fromDate || date.toDate !== dates[index - 1].toDate
    )

const getLatestValidToDate = (nosItems: MatrixNosItem[]): string => {
    const hasValidToDate = nosItems?.some((obj) => obj.validTo) || false

    if (!hasValidToDate) {
        return ''
    }

    const latestObject = nosItems.reduce((latest, obj) => {
        const validToDate = new Date(obj.validTo)
        const latestDate = new Date(latest.validTo)

        return validToDate > latestDate ? obj : latest
    }, nosItems[0])

    return latestObject.validTo
}

const formatDateString = (date: string, dateFormat: DateFormat = 'DD.MM.YYYY', locales: string = 'de-DE') => {
    const parsedDate = new Date(date)

    if (isNaN(parsedDate.getTime())) {
        throw new Error(`Invalid date format: error converting "${date}" to "${dateFormat}".`)
    }

    let year: 'numeric' | '2-digit' | undefined

    if (dateFormat.includes('YYYY')) {
        year = 'numeric'
    } else if (dateFormat.includes('YY')) {
        year = '2-digit'
    } else {
        year = undefined
    }

    const options: Intl.DateTimeFormatOptions = {
        day: dateFormat.includes('DD') ? '2-digit' : undefined,
        month: dateFormat.includes('MM') ? '2-digit' : undefined,
        year,
    }

    return parsedDate.toLocaleDateString(locales, options)
}

const stringToDate = (dateString: string): Date => new Date(`${dateString}T00:00:00`)
const dateToString = (date = new Date()) => useDateFormat(date, 'YYYY-MM-DD')

const filterValidAvailableDeliveryDates = (
    dates: MatrixAvailableDeliveryDate[],
    doFilter: boolean
): MatrixAvailableDeliveryDate[] => {
    if (!doFilter) {
        return dates
    }
    const now = dateToString()

    return dates?.filter((date) => date.deadlineDate >= now)
}

const getDeliveryDateChecked = (
    catalogId: string,
    supplier: string,
    supplierIsIntersport: boolean,
    date: string
): boolean => {
    const cachedDeliveryDate = getCachedData()

    if (cachedDeliveryDate) {
        const deliveryDateIsSaved = cachedDeliveryDate
            .find((item) => item.catalogId === catalogId && item.supplier === supplier)
            ?.deliveryDates?.find((dates) => dates.date === date)

        return deliveryDateIsSaved ? deliveryDateIsSaved?.flag : true
    }

    return !supplierIsIntersport
}

const updateDeliveryDateChecked = (checkedValue: boolean, catalogId: string, supplier: string, date: string) => {
    const cachedData = getCachedData() || []
    const existingObjectIndex = cachedData.findIndex(
        (item) => item.catalogId === catalogId && item.supplier === supplier
    )

    if (
        existingObjectIndex !== -1 &&
        cachedData[existingObjectIndex] &&
        cachedData[existingObjectIndex].deliveryDates
    ) {
        const index = cachedData[existingObjectIndex]?.deliveryDates?.findIndex((dates) => dates.date === date) ?? -1

        if (index !== -1) {
            cachedData[existingObjectIndex].deliveryDates[index].flag = checkedValue
        } else {
            cachedData[existingObjectIndex].deliveryDates.push({
                flag: checkedValue,
                date: date,
            })
        }
    } else {
        cachedData.push({
            catalogId: catalogId ?? '',
            supplier: supplier ?? '',
            deliveryDateType: 'date',
            deliveryDates: [
                {
                    flag: checkedValue,
                    date: date,
                },
            ],
        })
    }

    updateCachedData(cachedData)
}

const filterCachedDatesInRange = (
    allAvailableDeliveryDates: MatrixAvailableDeliveryDate[],
    cachedDeliveryDates: CachedDeliveryDateObj[]
) => {
    return cachedDeliveryDates.filter(({ date }) => {
        return filterValidAvailableDeliveryDates(allAvailableDeliveryDates, true).some((range) => {
            return date >= range.fromDate && date <= range.toDate
        })
    })
}

const getDeliveryDateFromCache = (
    allAvailableDeliveryDates: MatrixAvailableDeliveryDate[],
    catalogId: string,
    supplier: string
): CachedDeliveryDateObj[] => {
    const cachedData = getCachedData() || []
    const dates = cachedData.find((item) => item.catalogId === catalogId && item.supplier === supplier)

    return filterCachedDatesInRange(allAvailableDeliveryDates, dates?.deliveryDates.filter((date) => date.flag) || [])
}

const getDeliveryDateType = (catalogId: string, supplier: string): 'date' | 'week' => {
    const cachedData = getCachedData() || []
    const dates = cachedData.find((item) => item.catalogId === catalogId && item.supplier === supplier)

    return dates?.deliveryDateType || 'date'
}

const getDatesFromItems = (items: MatrixOrderItem[]): string[] => {
    const delivery_dates: string[] = items
        ?.map((orderItem) => orderItem.deliveryDate)
        ?.filter((value, deliveryIndex, self) => self.indexOf(value) === deliveryIndex)
        .sort() as string[]

    return delivery_dates ?? []
}

const getDeadlineDateForTab = (
    currentDeliveryDate: string | undefined,
    getAllAvailableDeliveryDates: (validOnly: boolean) => MatrixAvailableDeliveryDate[],
    voStore: boolean
) => {
    if (!currentDeliveryDate) {
        return ''
    }

    const allDeliveryDates = getAllAvailableDeliveryDates(false)
    const matchedDeadlineDate = allDeliveryDates.find(
        (date: MatrixAvailableDeliveryDate) =>
            currentDeliveryDate >= date.fromDate && currentDeliveryDate <= date.toDate
    )?.deadlineDate

    return matchedDeadlineDate && voStore ? matchedDeadlineDate : ''
}

const getActiveDeliveryDates = (
    matrixData: MatrixData,
    defaultDeliveryDates: MatrixDeliveryDate[],
    createDeliveryDateItem: (date: string, createForNos: boolean) => MatrixDeliveryDate,
    createDefaultDeliveryDate: () => MatrixDeliveryDate[]
): MatrixDeliveryDate[] => {
    const { catalogId, supplierName, items } = matrixData
    const cachedDeliveryDates = getDeliveryDateFromCache(matrixData.allAvailableDeliveryDates, catalogId, supplierName)

    const delivery_dates: string[] = getDatesFromItems(items?.orderItems ?? [])
    const defaultDates = defaultDeliveryDates.length > 0 ? [...defaultDeliveryDates] : createDefaultDeliveryDate()
    const cachedDatesExist = cachedDeliveryDates && cachedDeliveryDates?.length > 0

    if (cachedDatesExist && filterValidAvailableDeliveryDates(matrixData.allAvailableDeliveryDates, true).length > 0) {
        const uniqueDates = [
            ...new Set([...delivery_dates.map((date) => date), ...cachedDeliveryDates.map((date) => date.date)]),
        ]

        return uniqueDates.map((date) => createDeliveryDateItem(date, false))
    }

    if (delivery_dates.length > 0) {
        return [...delivery_dates.map((date) => createDeliveryDateItem(date, false))]
    }

    return [...defaultDates]
}

const setDeliveryDateFromCache = (
    activeDeliveryDate: MatrixDeliveryDate[],
    matrixData: MatrixData,
    getAllAvailableDeliveryDates: (validOnly: boolean) => MatrixAvailableDeliveryDate[],
    dateStringToWeek: (date: string) => string
): MatrixDeliveryDate[] => {
    const { catalogId, supplierName } = matrixData

    let deliveryDates = activeDeliveryDate

    const dates = getDeliveryDateFromCache(matrixData.allAvailableDeliveryDates, catalogId, supplierName)

    const shouldUpdateDeliveryDate = Boolean(
        filterAvailableDeliveryDates(getAllAvailableDeliveryDates)?.some((range) =>
            dates.some((elem) => isInRange(range, elem.date))
        )
    )

    if (shouldUpdateDeliveryDate) {
        deliveryDates = [
            ...dates.map((dateObj) => ({
                text:
                    getDeliveryDateType(catalogId, supplierName) === 'week'
                        ? `${dateStringToWeek(dateObj.date)} | ${formatDateString(dateObj.date, 'DD.MM.YY')}`
                        : formatDateString(dateObj.date, 'DD.MM.YYYY'),
                value: dateObj.date,
                icon: 'edit',
            })),
            ...deliveryDates,
        ]

        deliveryDates = deliveryDates
            .sort((a, b) => a.value.localeCompare(b.value))
            .filter(
                (matrixDeliveryDate, index) =>
                    index === 0 ||
                    JSON.stringify(matrixDeliveryDate.value) !== JSON.stringify(deliveryDates[index - 1].value)
            )
    }

    return deliveryDates
}

const updateDeliveryDateInCache = (
    supplierDeliveryDateData: CachedDeliveryDateData,
    catalogId: string,
    supplier: string
) => {
    const cachedData = getCachedData() || []
    const existingObjectIndex = cachedData.findIndex(
        (item) => item.catalogId === catalogId && item.supplier === supplier
    )

    if (existingObjectIndex !== -1) {
        cachedData[existingObjectIndex] = { ...supplierDeliveryDateData }
    } else {
        cachedData.push(supplierDeliveryDateData)
    }
    updateCachedData(cachedData)
}

export default function useMatrixDateHelper() {
    const { $hasRole, $t } = useNuxtApp()
    const {
        public: { deliveryDateInfoDays },
    } = useRuntimeConfig()
    warningDays = parseInt((deliveryDateInfoDays ?? '').toString(), 10)
    const nosDeliveryDates = ref<MatrixDeliveryDate[]>([])
    const deliveryDates = ref<MatrixDeliveryDate[]>([])
    const deliveryDateType = ref<number>(0)
    const deliveryDateIndex = ref<number>(0)
    const deliveryDateNosIndex = ref<number>(0)
    const currentDeliveryDate = computed(() => deliveryDates.value[deliveryDateIndex.value])
    const earliestDeliveryDate: ComputedRef<Date> = computed(() => {
        return new Date(deliveryDates.value.map((date) => date.value).sort()[0])
    })
    const nosValidToDate = ref<string>('')
    const gtinsExpiredForNewDate: Ref<string[]> = ref([])
    let matrixResponseData: MatrixData
    const currentNosDeliveryDate = computed(() => nosDeliveryDates.value[deliveryDateNosIndex.value])

    const dateStringToWeek = (date: string): string => `${$t('Matrix.cw')} ${useDateFormat(stringToDate(date), 'ww')}`

    const getAllAvailableDeliveryDates = (validOnly = true): MatrixAvailableDeliveryDate[] =>
        filterValidAvailableDeliveryDates(matrixResponseData.allAvailableDeliveryDates, validOnly)

    const getAllAvailableDeliveryDatesByGtin = (gtin: string, validOnly = true): MatrixAvailableDeliveryDate[] =>
        filterValidAvailableDeliveryDates(matrixResponseData.availableDeliveryDatesByGtin[gtin] ?? [], validOnly)

    const createDeliveryDateItem = (date: string, createForNos: boolean = false): MatrixDeliveryDate => {
        let formattedDate: string
        const cartClosed = matrixResponseData.items?.cartClosed
        if (matrixResponseData.deliveryDateType === dateTypeWeek && !createForNos) {
            formattedDate = `${dateStringToWeek(date)} | ${formatDateString(date, 'DD.MM.YY')}`
        } else if (matrixResponseData.deliveryDateType === dateTypeMonth) {
            formattedDate = useDateFormat(stringToDate(date), 'MM.YYYY')
        } else {
            formattedDate = formatDateString(date)
        }

        return {
            value: date,
            text: formattedDate,
            icon: (!cartClosed || createForNos) && !$hasRole(RoleTypes.SUPPLIER) ? 'edit' : '',
        }
    }

    const createDefaultDeliveryDate = (
        nosTabVisible: boolean = false,
        nosDate: boolean = false
    ): MatrixDeliveryDate[] => {
        const allDeliveryDates = getAllAvailableDeliveryDates()
            ?.map((date) => date.fromDate)
            .sort()
        const currentDateString = dateToString()
        let defaultDeliveryDates: MatrixDeliveryDate[]

        if (allDeliveryDates?.length > 0) {
            const earliestDate = allDeliveryDates[0]
            const defaultDate = earliestDate < currentDateString ? currentDateString : earliestDate
            defaultDeliveryDates = [createDeliveryDateItem(defaultDate, nosDate)]
        } else {
            defaultDeliveryDates = [createDeliveryDateItem(currentDateString, nosDate)]
        }

        if (nosTabVisible) {
            deliveryDateNosIndex.value = 0
        } else {
            deliveryDateIndex.value = 0
        }

        if (!nosDate && !nosTabVisible) {
            defaultDeliveryDates = setDeliveryDateFromCache(
                getActiveDeliveryDates(
                    matrixResponseData,
                    defaultDeliveryDates,
                    createDeliveryDateItem,
                    createDefaultDeliveryDate
                ),
                matrixResponseData,
                getAllAvailableDeliveryDates,
                dateStringToWeek
            )
        }

        return defaultDeliveryDates
    }

    const updateDateData = (matrixData: MatrixData, index: number = 0, updateDates: boolean = true) => {
        matrixResponseData = matrixData
        const { catalogId, supplierName, items } = matrixData

        if (!items?.loaded) {
            deliveryDates.value = createDefaultDeliveryDate()
        }

        if (matrixData.nosTabVisible && !items.nosLoaded) {
            nosDeliveryDates.value = createDefaultDeliveryDate(true)
        }

        const delivery_dates: string[] = items?.orderItems
            ? items.orderItems
                  .map((orderItem) => orderItem.deliveryDate)
                  .filter((value, deliveryIndex, self) => self.indexOf(value) === deliveryIndex)
                  ?.sort()
            : []

        const delivery_dates_nos: string[] = items?.nosItems
            ? items.nosItems
                  .map((nosItem) => nosItem.validFrom ?? createDefaultDeliveryDate(true)?.[0].value ?? dateToString())
                  .filter((value, nosIndex, self) => self.indexOf(value) === nosIndex)
                  .sort()
            : []

        const cachedDeliveryDate = getDeliveryDateFromCache(
            matrixData.allAvailableDeliveryDates,
            catalogId,
            supplierName
        )
        const needDefaultDeliveryDate =
            (cachedDeliveryDate && cachedDeliveryDate?.length > 0) || (delivery_dates.length === 0 && updateDates)
        if (needDefaultDeliveryDate) {
            deliveryDates.value = createDefaultDeliveryDate()
        } else {
            updateDates
                ? (deliveryDates.value = delivery_dates.map((date) => createDeliveryDateItem(date, false)))
                : null
            deliveryDateIndex.value = index
        }
        if (matrixData.nosTabVisible && delivery_dates_nos.length === 0 && updateDates) {
            nosDeliveryDates.value = createDefaultDeliveryDate(matrixResponseData.nosTabVisible, true)
        } else {
            updateDates
                ? (nosDeliveryDates.value = delivery_dates_nos.map((date) => createDeliveryDateItem(date, true)))
                : null
            deliveryDateNosIndex.value = 0
        }

        deliveryDateType.value = matrixData.deliveryDateType
        nosValidToDate.value = getLatestValidToDate(items?.nosItems)
    }

    const createDeliveryDate = (date: string, nosTabSelected: boolean = false) => {
        let index = 0
        const item = createDeliveryDateItem(date, nosTabSelected)
        for (let i = deliveryDates.value.length - 1; i >= 0; --i) {
            if (date > deliveryDates.value[i].value) {
                index = i + 1
                break
            }
        }
        deliveryDates.value.splice(index, 0, item)
        deliveryDateIndex.value = index

        return item
    }

    const getExpiredGtinsByDeliveryDate = (deliveryDate: string, gtins: string[]): string[] => {
        const dateToCheck = new Date(deliveryDate)

        const relevantDates = matrixResponseData.allAvailableDeliveryDates?.filter((date) => {
            const fromDate = new Date(date.fromDate)
            const toDate = new Date(date.toDate)

            return dateToCheck >= fromDate && dateToCheck <= toDate
        })

        const gtinsInRange = relevantDates.map((date) => date.gtin)

        return gtins.filter((gtin, index, self) => self.indexOf(gtin) === index && !gtinsInRange.includes(gtin))
    }

    const updateDeliveryDate = (
        date: string,
        oldDate: string,
        getOrderItemQuantitiesForDate: (targetDate: string) => MatrixStoredOrderItem[],
        updateMatrixOrderItemQuantity: (quantity: MatrixStoredOrderItem) => void
    ) => {
        const index = deliveryDates.value.findIndex((deliveryDate) => deliveryDate.value === oldDate)
        const newDeliveryDate = createDeliveryDateItem(date, false)
        const quantities = getOrderItemQuantitiesForDate(oldDate)
        const moduleItems = matrixResponseData.items.moduleItems
        const gtinsWithQuantity = quantities.map((item) => item.gtin).flat()

        gtinsExpiredForNewDate.value = getExpiredGtinsByDeliveryDate(date, gtinsWithQuantity)

        if (gtinsExpiredForNewDate.value.length && moduleItems.length) {
            return
        }

        for (const quantity of quantities) {
            const product = matrixResponseData.concreteProductsByGtin[quantity.gtin]
            const oldQuantity = quantity.storedQuantity ?? quantity.quantity ?? 0
            let data = { ...quantity }
            data.quantity = 0
            updateMatrixOrderItemQuantity(data)

            if (
                getAllAvailableDeliveryDatesByGtin(product.gtin).some(
                    (deliveryDate) => date >= deliveryDate.fromDate && date <= deliveryDate.toDate
                )
            ) {
                data = { ...data }
                data.quantity = oldQuantity
                data.deliveryDate = newDeliveryDate.value
                updateMatrixOrderItemQuantity(data)
            }
        }

        const currentTime = currentDeliveryDate.value?.value
        deliveryDates.value.splice(index, 1, newDeliveryDate)
        deliveryDates.value = deliveryDates.value.sort((left, right) => (left.value < right.value ? -1 : 1))

        for (let i = 0; i < deliveryDates.value.length; ++i) {
            if (deliveryDates.value[i].value === currentTime) {
                deliveryDateIndex.value = i
                break
            }
        }
    }

    const updateNosDeliveryDate = (
        date: string,
        validFromOld: string,
        getNosQuantitiesForDate: (targetDate: string) => NosSelectionItem[],
        updateMatrixNosQuantity: (quantity: NosSelectionItem, validFromDateOld: string) => void,
        validToDate: string
        // eslint-disable-next-line
    ) => {
        const newDeliveryDate = createDeliveryDateItem(date, true)
        const quantities = getNosQuantitiesForDate(validFromOld)
        for (const quantity of quantities) {
            const data = {
                ...quantity,
                validFrom: newDeliveryDate.value ?? null,
                validTo: validToDate ?? null,
            }

            updateMatrixNosQuantity(data, validFromOld)
        }

        nosDeliveryDates.value[0].value = date
        nosDeliveryDates.value[0].text = formatDateString(date)
    }

    const deleteDeliveryDate = (
        date: string,
        getOrderItemQuantitiesForDate: (targetDate: string) => MatrixStoredOrderItem[],
        updateMatrixOrderItemQuantity: (quantity: MatrixStoredOrderItem) => void
    ) => {
        const quantities = getOrderItemQuantitiesForDate(date)
        for (const quantity of quantities) {
            const updateData = { ...quantity }
            updateData.quantity = 0
            updateMatrixOrderItemQuantity(updateData)
        }

        for (let i = 0; i < deliveryDates.value.length; ++i) {
            if (deliveryDates.value[i].value === date) {
                deliveryDates.value.splice(i, 1)
                if (deliveryDateIndex.value >= i) {
                    deliveryDateIndex.value = Math.max(0, deliveryDateIndex.value - 1)
                }
                if (deliveryDates.value.length === 0) {
                    deliveryDates.value = createDefaultDeliveryDate()
                }
                break
            }
        }
    }

    const getDeadlineDateForActiveTab = computed(() =>
        getDeadlineDateForTab(currentDeliveryDate.value?.value, getAllAvailableDeliveryDates, useStore().isVoStore())
    )

    const getMinDeadlineForDeliveryDate = (date: string, returnFirstAvailableIfNotExist: boolean = false) => {
        let minDeadline = '2200-01-01'
        const allAvailableDates = getAllAvailableDeliveryDates()
        for (const deadline of allAvailableDates) {
            if (date >= deadline.fromDate && date <= deadline.toDate) {
                minDeadline = deadline.deadlineDate < minDeadline ? deadline.deadlineDate : minDeadline
            }
        }
        minDeadline = minDeadline === '2200-01-01' && date !== '' ? getDeadlineDateForActiveTab.value : minDeadline

        if (minDeadline === '' && returnFirstAvailableIfNotExist) {
            minDeadline = allAvailableDates?.[0]?.deadlineDate ? allAvailableDates?.[0]?.deadlineDate : minDeadline
        }

        return minDeadline
    }

    return {
        updateDateData,
        dateStringToWeek,
        stringToDate,
        dateToString,
        createDeliveryDate,
        updateDeliveryDate,
        updateNosDeliveryDate,
        deleteDeliveryDate,
        getAllAvailableDeliveryDates,
        getAllAvailableDeliveryDatesByGtin,
        getMinDeadlineForDeliveryDate,
        createDateString: dateToString,
        formatDateString,
        getDeliveryDateChecked,
        updateDeliveryDateChecked,
        updateDeliveryDateInCache,
        isInRange,
        filterAvailableDeliveryDates,
        deliveryDates,
        nosDeliveryDates,
        nosValidToDate,
        deliveryDateType,
        deliveryDateIndex,
        deliveryDateNosIndex,
        currentDeliveryDate,
        earliestDeliveryDate,
        currentNosDeliveryDate,
        gtinsExpiredForNewDate,
        getDeadlineDateForActiveTab,
        getDeadlineDateForTab,
        checkDeliveryDateDeadline,
        findInvalidOrders,
    }
}
