<template>
    <div :class="classNames">
        <input v-if="name" :name="name" type="hidden" :value="modelValue" />
        <div
            v-for="(item, i) in items"
            :key="i"
            :class="{
                'radio-button__item': true,
                active: i === modelValue || selected,
            }"
            @click="onClick(i)"
        >
            <div class="radio-button__content">
                <span v-if="!!item.name" class="text">
                    {{ item.name }}
                </span>
                <AtomIcon v-if="item.icon" :name="item.icon" :width="item?.width ?? 16" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
    defineProps<{
        name: string
        size?: 'l' | 'm' | 's' | 'xs'
        items?: {
            name?: string
            icon?: string
            width?: number
        }[]
        modelValue?: number | string
        disabled?: boolean
        selected?: boolean
    }>(),
    {
        items: undefined,
        modelValue: undefined,
        size: 'l',
        disabled: false,
    }
)

const emit = defineEmits<{ (e: 'update:modelValue', value: number): void }>()

const classNames = computed(
    () => `radio-button ${props.size} ${props.disabled ? 'disabled' : ''} ${props.selected ? 'is-active' : ''}`
)
const onClick = (i: number) => {
    if (props.modelValue !== i) {
        emit('update:modelValue', i)
    }
}
</script>

<style lang="scss" scoped>
.radio-button {
    cursor: pointer;
    height: rem(48);
    user-select: none;
    display: flex;
    justify-content: center;

    @include helper-border($setting-color-state-default);
    @include helper-border-radius(rem(5));

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &.m {
        height: rem(40);
    }

    &.s {
        height: rem(32);
    }

    &.xs {
        height: rem(20);
        width: rem(20);
    }

    &__item {
        flex: 1;
        transition: 0.3s;
        min-width: rem(50);
        display: flex;
        justify-content: center;
        align-items: center;

        @include helper-color(corporate-blue);
        @include helper-border-r($setting-color-state-default);
        @include helper-svg-size(16);

        .text {
            margin-right: rem(6);
        }

        &:nth-child(2) {
            border-radius: rem(5) 0 0 rem(5);
        }

        &:last-child {
            border-radius: 0 rem(5) rem(5) 0;
            border-right-color: transparent;
        }

        &.active {
            @include helper-color-bg(state-disabled);
        }
    }

    &__content {
        padding: 0 sp(s);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.white {
        @include helper-border($setting-color-gray-3 rem(1) solid);
        @include helper-color-bg(white);

        .radio-button__item {
            @include helper-color($setting-color-gray-3);
            @include helper-border($setting-color-gray-3 rem(1) solid);

            &.active {
                @include helper-color-bg(white);
                @include helper-border($setting-color-dark-blue rem(1) solid);
                @include helper-color($setting-color-dark-blue);
                @include helper-font-weight(bold);
            }
        }
    }

    &__carts,
    &__cart-flyout {
        @include helper-border-radius(100%);

        transition: border 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        box-sizing: border-box;
        width: rem(24);
        height: rem(24);
        position: relative;

        @include helper-border($setting-color-default-line);

        &.is-active {
            @include helper-border($setting-color-corporate-blue);
        }

        &.is-active::before {
            content: '';
            width: rem(12);
            height: rem(12);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include helper-color-bg(corporate-blue);
            @include helper-border($setting-color-corporate-blue);
            @include helper-border-radius(50%);
        }

        &:hover {
            @include helper-border($setting-color-corporate-blue);
        }
    }
}
</style>
